import React from 'react';

export default function Education() {
  const educations = [
    {
      university: 'University of new south wales',
      degree: 'Master of Commerce',
      url: 'https://www.business.unsw.edu.au/',
      major: 'Business Analytics',
      description: 'WAM: 76.37 | Distinction Average',
      from: 'FEB 2018',
      to: 'DEC 2019',
    },
    {
      university: 'CHULALONGKORN UNIVERSITY, THAILAND',
      degree: 'Bachelor of Engineering',
      url: 'https://www.eng.chula.ac.th/en/',
      major: 'Computer Engineering',
      description: 'GPA: 3.85/4.00 | 1s​t ​Class Honours',
      from: 'May 2011',
      to: 'Jul 2015',
    },
  ];

  return (
    <section
      className='resume-section p-3 p-lg-5 d-flex align-items-center container'
      id='education'
    >
      <div className='w-100'>
        <h2 className='mb-5'>Education</h2>

        {educations.map((education) => (
          <div
            key={education.university}
            className='resume-item d-flex flex-column flex-md-row justify-content-between mb-5'
          >
            <div className='resume-content'>
              <h3 className='mb-0'>{education.university}</h3>
              <div className='subheading'>
                <a
                  href={education.url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {education.degree}
                </a>{' '}
                <span className='d-inline d-md-none'>
                  <br />
                </span>
                | {education.major}
              </div>
              <div className='resume-date d-block d-lg-none'>
                <span className='font-heading'>
                  {education.from.toUpperCase()} - {education.to.toUpperCase()}
                </span>
              </div>
              <p>{education.description}</p>
            </div>
            <div className='resume-date text-md-right d-none d-lg-block '>
              <span className='font-heading'>
                {education.from.toUpperCase()} - {education.to.toUpperCase()}
              </span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
