import React from 'react';

export default function Profile() {
  return (
    <section
      className='resume-section p-3 p-lg-5 d-flex align-items-center d-block d-lg-none flex-wrap container'
      id='profile'
    >
      <div className='resume-item d-flex justify-content-center mb-5'>
        <div className='resume-content d-flex flex-column justify-content-center'>
          <h2 className='mb-5'>Let's connect</h2>
          <div className='p-2 text-center'>
            <img
              className='img-fluid img-profile rounded-circle mx-auto mb-2'
              src='img/profile.jpg'
              alt=''
            />
          </div>
          <div className='p-2 text-center'>
            <div className='d-flex flex-column justify-content-around align-items-center'>
              <div className='p-2'>
                <h4 className='text-primary'>
                  Pariyawit (Tim) Jatukannyaprateep
                </h4>
                <span className='font-body'>
                  <a
                    href='mailto:pariyawit.jat@gmail.com'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    pariyawit.jat@gmail.com
                  </a>
                </span>
              </div>
              <div className='social-icons p-2'>
                <a
                  href='https://www.linkedin.com/in/pariyawit'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fab fa-linkedin-in'></i>
                </a>
                <a
                  href='https://github.com/pariyawit'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fab fa-github'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
