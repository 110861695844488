import React from 'react';
import './App.scss';
import Nav from './components/Nav.js';
import About from './components/About.js';
import Portfolio from './components/Portfolio.js';
import Experience from './components/Experience.js';
import Education from './components/Education.js';
import Skill from './components/Skill.js';
import Profile from './components/Profile.js';

function App() {
  return (
    <div className='App'>
      <Nav></Nav>
      <div className='container-fluid p-0'>
        <About></About>
        <Experience></Experience>
        <hr className='m-0'></hr>
        <Skill></Skill>
        <hr className='m-0'></hr>
        <Portfolio></Portfolio>
        <hr className='m-0'></hr>
        <Education></Education>
        <hr className='m-0'></hr>
        <Profile></Profile>
      </div>
    </div>
  );
}

export default App;
