import React from 'react';

export default function Skill() {
  return (
    <section
      className='resume-section p-3 p-lg-5 d-flex align-items-center container'
      id='skills'
    >
      <div className='w-100'>
        <h2 className='mb-5'>Competencies</h2>

        <div className='subheading mb-3'>Programming Languages &amp; Tools</div>
        <ul className=''>
          <li>JavaScript, TypeScript</li>
          <li>NodeJs, RestAPI</li>
          <li>React, Redux, Mobx</li>
          <li>GraphQL</li>
        </ul>
      </div>
    </section>
  );
}
