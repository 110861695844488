import React from 'react';

export default function About() {
  return (
    <section
      className='resume-section-heading d-flex align-items-center'
      id='about'
    >
      <div className='container p-3 p-lg-5'>
        <h1>
          <span className='text-secondary'>hello,</span>
        </h1>
        <h1 className='mb-3'>
          <span className='text-white'>I'm Tim</span>
        </h1>

        <h3 className='mb-5 text-yellow'>
          <span style={{ textTransform: 'none' }}>aka </span>PARIYAWIT
          JATUKANNYAPRATEEP
        </h3>

        <p className='lead mb-5'>
          Software Engineer
          <br />
          <br />I am eager to learn and enjoy working in with a challenging
          problem in which I can demonstrate my skills and knowledge
          <span className='blinking-cursor'>_</span>
        </p>

        <div className='social-icons'>
          <a
            href='https://www.linkedin.com/in/pariyawit'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='fab fa-linkedin-in'></i>
          </a>
          <a
            href='https://github.com/pariyawit'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='fab fa-github'></i>
          </a>
        </div>
      </div>
    </section>
  );
}
