import React from 'react';

const portfolios = [
  {
    name: 'COVID-19 Map',
    img: 'covid-map.png',
    imgAlt: 'covid-19 map',
    url: 'https://covid-map.pariyawit.com/',
    technology: <>React, Context API, Hooks API, Sass, AWS S3</>,
    description: (
      <>
        Visualising COVID-19 cases using OpenStreetMap <br />
        The data is based on{' '}
        <a
          href='https://github.com/ExpDev07/coronavirus-tracker-api'
          target='_blank'
          rel='noopener noreferrer'
        >
          coronavirus-tracker-api
        </a>
      </>
    ),
  },
  {
    name: 'Pokémon GO',
    img: 'pokemon.png',
    imgAlt: 'pokemon',
    url: 'https://pokemongo.pariyawit.com/',
    technology: (
      <>
        React, Context & Hooks API, SASS, GraphQL, Firebase Authentication and
        Database
      </>
    ),
    description: (
      <>
        Mini Pokémon Go based on Google April Fools' joke in 2014
        <br />
        The Pokémon information comes from{' '}
        <a
          href='https://github.com/lucasbento/graphql-pokemon'
          target='_blank'
          rel='noopener noreferrer'
        >
          Pokémon GraphQL
        </a>
      </>
    ),
  },
  // {
  //   name: 'In Thai',
  //   img: 'inthai.png',
  //   imgAlt: 'inthai logo',
  //   url: 'https://inthai.herokuapp.com',
  //   technology: 'VueJS, Laravel, PostgreSQL',
  //   description:
  //     'Online Ordering System for a Restaurant. Enable customers to send an order and Admin to manage order and menu',
  // },
  {
    name: 'Instat (Instagram Statistic)',
    img: 'instat.png',
    imgAlt: 'instat',
    url: 'https://instat.pariyawit.com/',
    technology: (
      <>
        <br />
        Frontend: React, TypeScript, Redux,{' '}
        <a
          href='https://material-ui.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Material-UI
        </a>{' '}
        <br />
        Backend: NodeJs, Amazon Web Services (Lambda, API Gateway, EventBridge,
        DynamoDB)
      </>
    ),
    description: (
      <>Scraping number of followers from instagram influencer for analytics</>
    ),
  },

  // {
  //   name: 'Random Island Name',
  //   img: 'random-island-name.png',
  //   imgAlt: 'Random Island Name',
  //   url: 'https://random-island-name.pariyawit.com/',
  //   technology: 'React, HTML, CSS, SASS, AWS S3',
  //   description:
  //     'Random island name generator for your Animal Crossing New Horizon Island name idea',
  // },
  {
    name: 'Timeline',
    img: 'timeline.png',
    imgAlt: 'timeline',
    url: 'https://timeline.pariyawit.com/',
    technology: (
      <>
        <br />
        Frontend: React, TypeScript,{' '}
        <a
          href='https://material-ui.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Material-UI
        </a>{' '}
      </>
    ),
    description: (
      <>
        Create your own timeline based on{' '}
        <a
          href='https://www.facebook.com/prbangkok'
          target='_blank'
          rel='noopener noreferrer'
        >
          Bangkok PR
        </a>{' '}
        covid-19 patient's timeline template
      </>
    ),
  },
  // {
  //   name: 'IG',
  //   img: 'ig.png',
  //   imgAlt: 'IG logo',
  //   url: 'https://protected-waters-28658.herokuapp.com/',
  //   technology: ' Laravel, PostgresSQL, AWS-S3 for static file',
  //   description: 'Allowing you to Post and Follow others',
  // },
  {
    name: 'AIRCASH',
    img: 'aircash.png',
    imgAlt: 'aircash',
    url: 'https://aircash.pariyawit.com/#/',
    technology: 'AngularJS (aka Angular 1)',
    description:
      'A cashless payment registration mock-up as part of a Fintech hackaton competition',
  },
  {
    name: 'TABLEAU PORTFOLIO',
    img: 'tableau.png',
    imgAlt: 'inthai logo',
    url: 'https://public.tableau.com/profile/pariyawit#!/',
    technology: '',
    description: 'Data Visualisation using Tableau',
  },
];

export default function Portfolio() {
  return (
    <section
      className='resume-section p-3 p-lg-5 d-flex align-items-center resume-content container'
      id='portfolio'
    >
      <div className='w-100'>
        <h2 className='mb-5'>Portfolio</h2>
        <div className='d-flex flex-column align-items-stretch portfolio'>
          {portfolios.map((portfolio) => (
            <div
              key={portfolio.name}
              className='d-flex flex-column flex-md-row align-items-center py-3'
            >
              <a
                href={portfolio.url}
                target='_blank'
                rel='noopener noreferrer'
                className='d-flex pb-2 image'
              >
                <img src={'img/' + portfolio.img} alt={portfolio.imgAlt} />
              </a>
              <div className='align-self-center align-self-md-start d-flex flex-column w-100'>
                <a
                  href={portfolio.url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <h3
                    className='heading align-self-center align-self-md-start m-0'
                    style={{ fontWeight: '700', color: '#01579B' }}
                  >
                    {portfolio.name}
                  </h3>
                </a>

                <p className='portfolio__description align-self-start align-self-md-start'>
                  <br />
                  {portfolio.description}
                </p>
                {portfolio.technology ? (
                  <span className='align-self-start portfolio__technology'>
                    Utilised: <strong>{portfolio.technology}</strong>
                  </span>
                ) : (
                  ''
                )}
                <a
                  href={portfolio.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='mb-2 align-self-end'
                >
                  View
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
