import React from 'react';

export default function Nav() {
  return (
    <nav
      className='navbar navbar-expand-lg bg-nav navbar-light bg-light'
      id='sideNav'
    >
      <a className='navbar-brand js-scroll-trigger' href='#page-top'>
        <span className='d-none d-lg-block'>
          <img
            className='img-fluid img-profile rounded-circle mx-auto mb-2'
            src='img/profile.jpg'
            alt=''
          />
        </span>
      </a>
      <div className='collapse navbar-collapse' id='navbarSupportedContent'>
        <ul className='navbar-nav'>
          <div className='d-none d-lg-block p-3'>
            <h4 className='text-primary'>
              <a
                href='https://www.linkedin.com/in/pariyawit'
                className='text-blue'
              >
                Pariyawit (Tim) Jatukannyaprateep
              </a>
            </h4>
            <span className=''>
              <a href='mailto:pariyawit.jat@gmail.com'>
                pariyawit.jat@gmail.com
              </a>
            </span>
          </div>
          <li className='nav-item'>
            <a className='nav-link js-scroll-trigger' href='#about'>
              About
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link js-scroll-trigger' href='#experience'>
              Experience
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link js-scroll-trigger' href='#skills'>
              Competencies
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link js-scroll-trigger' href='#portfolio'>
              Portfolio
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link js-scroll-trigger' href='#education'>
              Education
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
