import React from 'react';

const list = (tasks) => (
  <ul>
    {tasks.map((t) => (
      <li>
        <span>{t}</span>
      </li>
    ))}
  </ul>
);

export default function Experience() {
  const experiences = [
    {
      company: 'Domain Group',
      url: 'https://www.domain.com.au/group/',
      position: 'Software Engineer',
      location: 'Pyrmont, NSW',
      from: 'NOV 2021',
      to: 'PRESENT',
      tasks: (
        <>
          {/* <b>Seeker Explorer Team</b> ( OCT 2020 - OCT 2021 ){list([])}
          <b>Seeker Search Team</b> ( NOV 2021 - OCT 2021 ){list([])} */}
        </>
      ),
    },
    {
      company: 'Edval Timetables',
      url: 'https://www.edval.education',
      position: 'Software Developer',
      location: 'Surry Hills, NSW',
      from: 'Jun 2020',
      to: 'OCT 2021',
      tasks: (
        <>
          <b>Core Tools Team</b> ( OCT 2020 - OCT 2021 )
          {list([
            'Build and publish React frontend package for internal developers through npm registry to ensure code reusability and scalability',
            'Develop frontend using React/Redux',
            'Write unit test using Jest',
          ])}
          <b>Cloud Engineering Team</b> ( JUN 2020 - OCT 2020 )
          {list([
            'Developed web application using VueJS',
            'Designed and developed serverless backend using AWS Lambda(NodeJs) and DynamoDB',
            'Implemented new features and fixed bugs using Java Spring Boot',
            'Wrote unit test to achieve high code coverage using Mocha and Sinon',
          ])}
        </>
      ),
    },
    {
      company: 'Self-Employed',
      url: 'https://pariyawit.com',
      position: 'Web Developer',
      location: '',
      from: 'Jun 2019',
      to: 'Jun 2020',
      tasks: [
        'Developed and maintained websites that compatible across different browsers and devices',
        'Created layouts and features according to requirements and designs',
        'Managed tasks and maintained schedules to assure on-time delivery',
      ],
    },
    {
      company: 'The Studio',
      url: 'https://www.thestudio.org.au/',
      position: 'Business Analyst Intern',
      location: 'Sydney, NSW',
      from: 'Jun 2019',
      to: 'Aug 2019',
      tasks: [
        'Planned and managed the market analysis project with fellow interns',
        'Explored studios for-hire industry to consolidate the business, market, and financial analysis',
        'Used MS Excel to assess and calculate cost-revenue model resulting in adjusted package and pricing plan',
      ],
    },
    {
      company: 'NEAS',
      url: 'https://neas.org.au/',
      position: 'Conference Coordinator Intern',
      location: 'North Sydney, NSW',
      from: 'May 2019',
      to: '',
      tasks: [
        'Supported in organising an annual conference with 30+ speakers and 300+ delegates from around the world',
        'Update the conference’s speakers and content on Wordpress CMS platform',
        'Compiled attendee information and survey results to develop a conference report',
        'Explored Wix and Squarespace for building website in the future',
      ],
    },
    {
      company: 'UNSW Bookshop',
      url: 'https://www.bookshop.unsw.edu.au/',
      position: 'SALES ASSISTANT',
      location: 'Kensington, NSW',
      from: 'Feb 2019',
      to: 'Jun 2019',
      tasks: [
        'Communicated with customers in a friendly and professional manner',
        'Operated PoS system responding to 100+ customers during a shift',
      ],
    },
    {
      company: 'Bangkok Bank PCL',
      url: 'https://www.bangkokbank.com/en',
      position: 'Programmer',
      location: 'Bangkok, Thailand',
      from: 'Aug 2015',
      to: 'Aug 2017',
      tasks: [
        'Implemented registration platform for customers to transfer and receive funds resulting in over 10,000 registrations through the channel within the first week',
        'Collaborated with business units to identify challenges in existing system and gather business requirements',
        'Communicated within technology division to address user issues and develop solutions',
        'Prepared and presented system prototypes to business units for approval',
        'Developed a system requirement specification according to industry standard for system implementation',
      ],
    },
    {
      company: 'Weathernews Inc',
      url: 'https://global.weathernews.com/',
      position: 'Software Engineer Intern',
      location: 'Shiba, Japan',
      from: 'Jun 2014',
      to: 'Aug 2017',
      tasks: [
        'Designed a system to evaluate weather conditions for an airline resulting in savings process time',
        'Explored and interpreted raw weather data to assist pilots in decision making',
        'Developed a web prototype to present to supervisors using real-time data',
      ],
    },
    {
      company: 'Télécom Bretagne',
      url: 'https://www.telecom-bretagne.eu/about/',
      position: 'Research',
      location: 'Brittany, France',
      from: 'Apr 2014',
      to: 'May 2017',
      tasks: [
        'Researched a recommendation system to suggest books for university students',
        'Implemented a book recommendation website based on the research and library data',
      ],
    },
  ];

  return (
    <section
      className='resume-section p-3 p-lg-5 d-flex justify-content-center container'
      id='experience'
    >
      <div className='w-100'>
        <h2 className='mb-5'>Experience</h2>

        {experiences.map((experience) => (
          <div
            key={experience.company}
            className='resume-item d-flex flex-column flex-md-row justify-content-between mb-5'
          >
            <div className='resume-content'>
              <h3 className='mb-0'>{experience.position}</h3>
              <div className='subheading'>
                <a
                  href={experience.url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {experience.company}
                </a>
                , {experience.location}
              </div>
              <div className='resume-date d-block d-lg-none'>
                <span className='font-heading'>
                  {experience.from.toUpperCase()}{' '}
                  {experience.to ? '- ' + experience.to.toUpperCase() : ''}
                </span>
              </div>
              {Array.isArray(experience.tasks)
                ? list(experience.tasks)
                : experience.tasks}
            </div>
            <div className='resume-date text-md-right d-none d-lg-block'>
              <span className='font-heading'>
                {experience.from.toUpperCase()}{' '}
                {experience.to ? '- ' + experience.to.toUpperCase() : ''}
              </span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
